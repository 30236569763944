@use "sass:math";

// mixin
//Percent
// @function percent($px, $from) {
//   $result: math.div($px, $from) * 100%;
//   @return $result;
// }

//REM
@function rem($px) {
  $result: ($px / 16) + rem;
  @return $result;
}

//EM
// @function em($px, $current: 16) {
//   $result: math.div($px, $current) + em;
//   @return $result;
// }

// Grids
@mixin grid($type: fit, $min: 280px, $max: 1fr, $gap: 30px) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(auto-#{$type}, minmax($min, $max));
}

// Адаптивная формула для текста в Rem
$maxWidth: 1440;
@mixin adaptiv-font($min: 16, $maxF: 20, $minWidth: 320) {
  $minF: $min / 16;
  font-size: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для line-height в Rem
$maxWidth: 1440;
@mixin adaptiv-lineHeight($min: 16, $maxF: 20, $minWidth: 320) {
  $minF: $min / 16;
  line-height: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для ширины блоков в Px
@mixin adaptiv-block($min: 16, $maxF: 20, $maxWidth: 1440, $minWidth: 320) {
  width: calc(
    #{$min + px} + (#{$maxF} - #{$min}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для calc в px
@function adaptiv-number($min: 16, $maxF: 20, $maxWidth: 1440, $minWidth: 320) {
  $result: calc(
    #{$min + px} + (#{$maxF} - #{$min}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
  @return $result;
}

// img
@mixin img($type: contain) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: $type;
}

// Адаптивная формула для текста в Rem
@function adaptiv-num-rem(
  $min: 16,
  $maxF: 20,
  $maxWidth: 1440,
  $minWidth: 320
) {
  $minF: $min / 16;
  $result: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
  @return $result;
}

// mixin

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(130%);
  -webkit-transform: translateX(130%);
  -moz-transform: translateX(130%);
  -ms-transform: translateX(130%);
  -o-transform: translateX(130%);
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.menu.active {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 0;
}

.menu__content {
  width: rem(650);
  height: 100%;
  background-color: #e7c200;
  position: absolute;
  right: 0;
  z-index: 1;
  padding: rem(50) rem(30);

  .logo__main {
    flex: 0 1 auto;
    padding: 0px;

    @media (max-width: 1280px) {
      padding-top: adaptiv-num-rem(30, 0, 1280, 320);
    }

    .logo {
      @media (max-width: 1280px) {
        width: adaptiv-num-rem(27, 79, 1280, 320);
        height: adaptiv-num-rem(41, 121, 1280, 320);
      }

      svg {
        width: adaptiv-num-rem(27, 79, 1280, 320);
        height: adaptiv-num-rem(41, 121, 1280, 320);
      }
    }

    .logo__text {
      h2 {
        @media (max-width: 1280px) {
          font-size: adaptiv-num-rem(12, 20, 1280, 320);
          line-height: adaptiv-num-rem(16, 24, 1280, 320);
        }
      }

      p {
        @media (max-width: 1280px) {
          font-size: adaptiv-num-rem(10, 14, 1280, 320);
          line-height: adaptiv-num-rem(12, 17, 1280, 320);
        }
      }
    }
  }

  @media (min-width: 1440px) {
    width: 45%;
  }

  @media (max-width: 1280px) {
    width: adaptiv-num-rem(250, 650, 1280, 320);
    padding: rem(50) adaptiv-num-rem(15, 30, 1280, 320);
  }

  ul {
    flex: 1 1 auto;
    gap: rem(35);
    margin-top: rem(75);

    @media (max-width: 1280px) {
      gap: adaptiv-num-rem(25, 35, 1280, 320);
    }
    li {
      align-items: center;
      display: flex;

      a {
        font-weight: 500;
        font-size: rem(20);
        line-height: rem(24);
        color: #ffffff;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;

        @media (max-width: 1280px) {
          font-size: adaptiv-num-rem(16, 20, 1280, 320);
          line-height: adaptiv-num-rem(20, 24, 1280, 320);
        }

        &:hover {
          opacity: 0.8;
          text-decoration: underline;
        }
      }

      &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        width: rem(32);
        height: rem(32);
        margin-right: rem(10);
        flex-shrink: 0;

        @media (max-width: 1280px) {
          width: adaptiv-num-rem(22, 32, 1280, 320);
          height: adaptiv-num-rem(22, 32, 1280, 320);
        }
      }

      &:nth-child(1)::before {
        background-image: url(../../public/img/icon/main.svg);
      }
      &:nth-child(2)::before {
        background-image: url(../../public/img/icon/price.svg);
      }
      &:nth-child(3)::before {
        background-image: url(../../public/img/icon/radiation.svg);
      }
      &:nth-child(4)::before {
        background-image: url(../../public/img/icon/disposal.svg);
      }
      &:nth-child(5)::before {
        background-image: url(../../public/img/icon/calc.svg);
      }
      &:nth-child(6)::before {
        background-image: url(../../public/img/icon/checklist.svg);
      }
    }
  }
}
