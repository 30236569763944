@use "sass:math";

// mixin
//Percent
// @function percent($px, $from) {
//   $result: math.div($px, $from) * 100%;
//   @return $result;
// }

//REM
@function rem($px) {
  $result: ($px / 16) + rem;
  @return $result;
}

//EM
// @function em($px, $current: 16) {
//   $result: math.div($px, $current) + em;
//   @return $result;
// }

// Grids
@mixin grid($type: fit, $min: 280px, $max: 1fr, $gap: 30px) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(auto-#{$type}, minmax($min, $max));
}

// Адаптивная формула для текста в Rem
$maxWidth: 1440;
@mixin adaptiv-font($min: 16, $maxF: 20, $minWidth: 320) {
  $minF: $min / 16;
  font-size: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для line-height в Rem
$maxWidth: 1440;
@mixin adaptiv-lineHeight($min: 16, $maxF: 20, $minWidth: 320) {
  $minF: $min / 16;
  line-height: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для ширины блоков в Px
@mixin adaptiv-block($min: 16, $maxF: 20, $maxWidth: 1440, $minWidth: 320) {
  width: calc(
    #{$min + px} + (#{$maxF} - #{$min}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для calc в px
@function adaptiv-number($min: 16, $maxF: 20, $maxWidth: 1440, $minWidth: 320) {
  $result: calc(
    #{$min + px} + (#{$maxF} - #{$min}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
  @return $result;
}

// img
@mixin img($type: contain) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: $type;
}

// Адаптивная формула для текста в Rem
@function adaptiv-num-rem(
  $min: 16,
  $maxF: 20,
  $maxWidth: 1440,
  $minWidth: 320
) {
  $minF: $min / 16;
  $result: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
  @return $result;
}

// mixin

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  overflow: auto;

  &__content {
    padding: 20px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background-color: #ffffff;
    max-width: rem(720);
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;

    &.active {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
    }

    @media (max-width: 800px) {
      max-width: none;
      width: 90vw;
    }
  }

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.modal-top {
  h2,
  h6 {
    color: #000000;
    text-align: center;
  }

  h6 {
    padding: rem(45) 0 rem(75) 0;
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(24);

    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(16, 20, 1280, 320);
      line-height: adaptiv-num-rem(20, 24, 1280, 320);
    }

    @media (max-width: 550px) {
      font-size: adaptiv-num-rem(16, 20, 1280, 320);
      line-height: adaptiv-num-rem(20, 24, 1280, 320);
      padding: adaptiv-num-rem(30, 45, 550, 320) 0
        adaptiv-num-rem(40, 75, 550, 320) 0;
    }
  }
}

.modal-main {
  max-width: rem(680);
  margin: 0 auto;
  gap: rem(20);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(rem(280), 1fr));

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fit, minmax(rem(200), 1fr));
  }
}
