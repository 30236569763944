@import "./fonts.scss";
@import "./base.scss";
@import "./mixins.scss";

// ---------Оболочка-----------
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.container {
  max-width: rem(1030);
  margin: 0 auto;
  padding: 0 rem(15) 0 rem(15);
}
// ---------Оболочка-----------

// ---------MAIN-----------
.main {
  flex: 1 1 auto;
}
// ---------MAIN-----------

// ---------стандартные flex стили-----------
.d-fl {
  display: flex;
}

.d-fl-col {
  display: flex;
  flex-direction: column;
}

.d-fl-row {
  display: flex;
  flex-direction: row;
}

.d-fl-wrap {
  display: flex;
  flex-wrap: wrap;
}
// ---------стандартные flex стили-----------

// ---------BEM-----------
.btn {
  display: inline-block;
}

.section-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: rem(10);
}

.section__block {
  flex: 1 1 rem(280.5);
}

.logo__main {
  background: #e7c200;
  flex: 1 1 auto;
  padding: rem(50) 0 rem(50) rem(220);

  @media (max-width: 1440px) {
    padding: rem(50) 0 rem(50) adaptiv-num-rem(15, 220, 1440, 1000);
  }
  @media (max-width: 1000px) {
    padding: rem(50) 0 rem(50) rem(15);
  }

  a {
    align-items: center;

    &:hover .logo > svg {
      fill: #ffffff;
    }
  }
}

.logo {
  max-width: 79px;
  max-height: 121px;
  cursor: pointer;

  svg {
    object-fit: contain;
    width: 100%;
    max-height: 121px;
    fill: #000000;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
}

.logo__text {
  padding: 0 rem(30) 0 rem(30);

  @media (max-width: 1280px) {
    padding: 0 adaptiv-num-rem(10, 30, 1280, 320) 0
      adaptiv-num-rem(10, 30, 1280, 320);
  }

  h2,
  p {
    white-space: nowrap;
  }
  h2 {
    font-weight: 600;
    font-size: rem(20);
    line-height: rem(24);
    text-transform: uppercase;
    padding-bottom: rem(5);

    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(14, 20, 1280, 320);
      line-height: adaptiv-num-rem(18, 24, 1280, 320);
    }
  }

  p {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(17);
    text-transform: uppercase;
    color: #000000;

    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(10, 14, 1280, 320);
      line-height: adaptiv-num-rem(13, 17, 1280, 320);
    }
  }
}

// Блок с телефонами
.info {
  background: #17222d;
  padding: 0 rem(10);
  flex: 1 1 auto;
  justify-content: center;

  ul {
    & > li > ul {
      padding-top: 5px;
    }

    align-items: center;
    justify-content: center;
    gap: rem(15);

    li {
      font-size: rem(16);
      line-height: rem(20);
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 600;
      transition: 0.3s;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;

      @media (max-width: 1280px) {
        font-size: adaptiv-num-rem(10, 16, 1280, 320);
        line-height: adaptiv-num-rem(14, 20, 1280, 320);
      }

      &:hover {
        color: #ffd502;
      }

      &:hover a {
        opacity: 0.7;
      }

      a {
        font-weight: 500;
        position: relative;
        margin-top: rem(5);
        color: #ffffff;

        &:hover {
          opacity: 0.7;
        }

        &::before {
          content: "";
          display: block;
          background-image: url(../public/img/icon/phone.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: -20px;
          z-index: 0;
          margin-top: -8px;
          transition: 0.3s;
          -webkit-transition: 0.3s;
          -moz-transition: 0.3s;
          -ms-transition: 0.3s;
          -o-transition: 0.3s;
        }
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
      }
    }
  }
}

.btn {
  display: inline-block;
}

.title__block {
  font-style: normal;
  font-weight: 500;
  font-size: rem(32);
  line-height: rem(39);
  text-transform: uppercase;
  color: #000000;

  @media (max-width: 1280px) {
    font-size: adaptiv-num-rem(22, 32, 1280, 320);
    line-height: adaptiv-num-rem(29, 39, 1280, 320);
  }
}
