@use "sass:math";

// mixin
//Percent
// @function percent($px, $from) {
//   $result: math.div($px, $from) * 100%;
//   @return $result;
// }

//REM
@function rem($px) {
  $result: ($px / 16) + rem;
  @return $result;
}

//EM
// @function em($px, $current: 16) {
//   $result: math.div($px, $current) + em;
//   @return $result;
// }

// Grids
@mixin grid($type: fit, $min: 280px, $max: 1fr, $gap: 30px) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(auto-#{$type}, minmax($min, $max));
}

// Адаптивная формула для текста в Rem
$maxWidth: 1440;
@mixin adaptiv-font($min: 16, $maxF: 20, $minWidth: 320) {
  $minF: $min / 16;
  font-size: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для line-height в Rem
$maxWidth: 1440;
@mixin adaptiv-lineHeight($min: 16, $maxF: 20, $minWidth: 320) {
  $minF: $min / 16;
  line-height: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для ширины блоков в Px
@mixin adaptiv-block($min: 16, $maxF: 20, $maxWidth: 1440, $minWidth: 320) {
  width: calc(
    #{$min + px} + (#{$maxF} - #{$min}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
}

// Адаптивная формула для calc в px
@function adaptiv-number($min: 16, $maxF: 20, $maxWidth: 1440, $minWidth: 320) {
  $result: calc(
    #{$min + px} + (#{$maxF} - #{$min}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
  @return $result;
}

// img
@mixin img($type: contain) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: $type;
}

// Адаптивная формула для текста в Rem
@function adaptiv-num-rem(
  $min: 16,
  $maxF: 20,
  $maxWidth: 1440,
  $minWidth: 320
) {
  $minF: $min / 16;
  $result: calc(
    #{$minF + rem} + (#{$maxF} - #{$minF * 16}) * ((100vw - #{$minWidth + px}) /
          (#{$maxWidth} - #{$minWidth}))
  );
  @return $result;
}

// mixin

@import url("https://fonts.googleapis.com/css2?family=Tinos:wght@400;700&display=swap");

.pages__section {
}

.pages__background {
  width: 100%;
  height: 350px;
}

.profit__background {
  background: url(../../public/img/backgroud/1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}

.processing__background {
  background: url(../../public/img/backgroud/9.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.used__background {
  background: url(../../public/img/backgroud/13.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center -200px;
}

.calc__background {
  background: url(../../public/img/backgroud/11.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center -200px;
}

.rules__background {
  background: url(../../public/img/backgroud/10.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
  //   url(../../public/img/backgroud/10.jpg);
}

.pages__article {
  max-width: rem(800);
  margin: 0 auto;
  padding: rem(50) 0;

  h1 {
    font-family: "Tinos", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 1.5px;
    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(20, 40, 1280, 320);
      line-height: adaptiv-num-rem(26, 46, 1280, 320);
    }

    span {
      color: #f4cd00;
      font-family: "Tinos", serif;
      letter-spacing: 1.5px;
    }
  }

  p {
    font-family: "Tinos", serif;
    letter-spacing: 0.5px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(14, 18, 1280, 320);
      line-height: adaptiv-num-rem(18, 22, 1280, 320);
    }
  }
}

.title-top {
  justify-content: space-between;
  span {
    font-family: "Tinos", serif;
    letter-spacing: 0.5px;
    font-weight: 400;
    font-size: rem(12);
    line-height: rem(20);
    color: #9c9c9c;

    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(10, 12, 1280, 320);
      line-height: adaptiv-num-rem(18, 20, 1280, 320);
    }
  }
}

.content-top,
.content-bottom {
  padding: rem(75) 0;
}

.content-center {
  position: relative;
  h3 {
    font-family: "Tinos", serif;
    letter-spacing: 0.5px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: #f4cd00;
    text-align: center;

    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(18, 30, 1280, 320);
      line-height: adaptiv-num-rem(24, 36, 1280, 320);
    }
  }
  a {
    font-family: "Tinos", serif;
    letter-spacing: 0.5px;
    display: block;
    align-self: center;
  }
}

.pages__btn {
  font-family: "Tinos", serif;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: rem(5) rem(10);
  border-radius: rem(8);
  -webkit-border-radius: rem(8);
  -moz-border-radius: rem(8);
  -ms-border-radius: rem(8);
  -o-border-radius: rem(8);
  text-transform: uppercase;
  font-size: 20px;
  line-height: 26px;
  margin: rem(40) 0 rem(15);
  letter-spacing: 1.5px;
  background-color: #161616;
  color: #ffffff;
  opacity: 0.9;
  align-self: center;

  @media (max-width: 1280px) {
    font-size: adaptiv-num-rem(18, 20, 1280, 320);
    line-height: adaptiv-num-rem(24, 26, 1280, 320);
  }

  &:hover {
    background-color: #000000;
    opacity: 1;
  }
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.pages__email {
  font-family: "Tinos", serif;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(20);
  color: #9c9c9c;
}

.society {
  position: absolute;
  left: -100px;
  top: -200px;
  gap: rem(20);
}

.society__block {
  display: block;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  border: 1px solid #ffffff;

  svg {
    width: 100%;
    height: 100%;
    fill: #acacac;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  &:hover {
    border: 1px solid #e7c200;
  }

  &:hover svg {
    fill: #000000;
  }
}

.content-center--list {
  padding: rem(75) 0;
}

.content__list {
  ul {
    li {
      span {
        display: block;
        font-size: 22px;
        line-height: 28px;
        padding-bottom: rem(5);
        font-weight: 600;
        font-family: "Tinos", serif;
        letter-spacing: 0.5px;

        @media (max-width: 1280px) {
          font-size: adaptiv-num-rem(18, 22, 1280, 320);
          line-height: adaptiv-num-rem(22, 28, 1280, 320);
        }
      }
    }
  }
  li {
    padding: rem(5) 0;
    font-size: 18px;
    line-height: 22px;
    font-family: "Tinos", serif;
    letter-spacing: 0.5px;

    @media (max-width: 1280px) {
      font-size: adaptiv-num-rem(14, 18, 1280, 320);
      line-height: adaptiv-num-rem(18, 22, 1280, 320);
    }
  }
}

.content-list--type {
  li {
    margin-left: rem(15);
    list-style-type: disc;
  }
}
